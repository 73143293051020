let nextId = 0;

const loop = (selector, callback, id, onlyFirst = false) => {
  let elements = [];
  if (onlyFirst) {
    const element = document.querySelector(selector);
    if (element) {
      elements.push(element);
    }
  } else {
    elements = document.querySelectorAll(selector);
  }
  elements.forEach((element) => {
    if (element) {
      //console.log(selector);
      if (!element.dataset.tjupdated) {
        element.dataset.tjupdated = "";
      }
      const ids = element.dataset.tjupdated;
      if (ids.indexOf(id) === -1) {
        callback(element);
        element.dataset.tjupdated = `${id} ${ids}`;
      }
    }
  });
};

const loopForElement = (selector, callback) => {
  const id = `loopForElement${nextId}`;
  nextId += 1;

  window.setInterval(() => {
    loop(selector, callback, id);
  }, 40);
};

const loopForFirstElement = (selector, callback) => {
  const id = `loopForElement${nextId}`;
  nextId += 1;

  window.setInterval(() => {
    loop(selector, callback, id, true);
  }, 40);
};

module.exports = { loopForElement, loopForFirstElement };
