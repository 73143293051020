const { loopForElement } = require('alias-lib/loop-for-element')

const resizeIframe = (event, iframe) => {
  if (
    (iframe.classList.contains('airiane-search-engine') &&
      event.data.iframe_type === 'airiane-search-engine') ||
    (iframe.classList.contains('airiane-destinations-list') &&
      event.data.iframe_type === 'airiane-destinations-list') ||
    (iframe.classList.contains('airiane-flight-schedule') &&
      event.data.iframe_type === 'airiane-flight-schedule')
  ) {
    iframe.setAttribute('height', `${event.data.height}`)
  }
}

const eventListeners = []

const initIframe = iframe => {
  iframe.setAttribute('scrolling', 'no')
  iframe.setAttribute('frameborder', '0')
  if (window.addEventListener) {
    eventListeners.push(
      window.addEventListener(
        'message',
        event => {
          resizeIframe(event, iframe)
        },
        false
      )
    )
  } else if (window.attachEvent) {
    eventListeners.push(
      window.attachEvent('onmessage', event => {
        resizeIframe(event, iframe)
      })
    )
  }
}

const init = () => {
  loopForElement('iframe.airiane-search-engine', iframe => {
    initIframe(iframe)
  })

  loopForElement('iframe.airiane-destinations-list', iframe => {
    initIframe(iframe)
  })

  loopForElement('iframe.airiane-flight-schedule', iframe => {
    initIframe(iframe)
  })
}

window.addEventListener('load', init)

module.exports = {
  init,
}
