const resize = require('./resize');

const loadIframe = (
  options = {
    container: '.airiane-destinations-list-container',
    idPart: 'idPart',
    lang: 'fr'
  }
) => {
  const target = document.querySelector(options.container);
  if (!target) return;

  // on bezier, the hash is not well placed
  const pos = window.location.hash.indexOf('?');
  let params = '';
  if (pos === -1) {
    // look into the search
    params = window.location.search;
    // params = window.location.search.replace("?", "");
  } else {
    params = window.location.hash.substr(pos);
  }

  params = params.replace(/fs-/g, '');

  const myHost = window.location.hostname;

  const ourHosts = [
    'localhost',
    'test.engines.airiane.com',
    'preprod.engines.airiane.com',
    'engines.airiane.com',
    'www.aeroports-voyages.fr'
  ];

  let url;

  if (ourHosts.indexOf(myHost) >= 0) {
    url = `${document.location.origin}/${options.lang}/flight-routes/${
      options.idPart
    }`;
  } else {
    url = `//www.booking.aeroports-voyages.fr/${options.lang}/flight-routes/${
      options.idPart
    }`;
  }

  if (params === '') {
    params += `?id_part=${options.idPart}`;
  }

  if (params.indexOf('id_part') === -1) {
    params += `&id_part=${options.idPart}`;
  }

  url += params;
  target.innerHTML = '';
  const iframe = document.createElement('iframe');
  target.appendChild(iframe);
  iframe.src = url;
  iframe.setAttribute('width', '100%');
  iframe.setAttribute('class', 'airiane-flight-schedule');
  iframe.setAttribute('scrolling', 'no');
  iframe.setAttribute('frameborder', '0');

  resize.init();
};

module.exports = { loadIframe };
